export const getQueryParam = (param) => {
  const href = window.location.href;
  const reg = new RegExp('[?&]' + param + '=([^&#]*)', 'i');
  const string = reg.exec(href);
  return string ? string[1] : null;
}

export const removeDomain = (url, prefix = '/localproxy') => {
  if (!url || process.env.NODE_ENV !== 'development') return url || ''
  const domain = url.match(/https?:\/\/[^/]+/)[0]
  return prefix + url.replace(/https?:\/\/[^/]+/, '') + `?domain=${encodeURIComponent(domain)}`
}
