<template>
  <div class="login-wrap">
    <div class="auth-wrap">
      <div class="title">
        <img src="../assets/animix.png" alt="" style="width: 30%">
      </div>
      <div class="p1">Welcome Back</div>
      <div class="p2" style="transform: translateY(10px)">Access your account by signing in with Discord below
      </div>
      <a-button class="auth-btn" @click="handleAuth">
        <img src="../assets/discordlogo.png" alt="" style="width: 24px;height: 24px;margin-top: 9px;margin-right: 8px">Sign In with Discord
        <a-spin v-if="loading" style="margin-left: 14px;margin-top: 8px;">
          <a-icon slot="indicator" type="loading" style="font-size: 24px;color: #000" spin />
        </a-spin>
      </a-button>
    </div>
  </div>
</template>

<script>
import { getQueryParam } from '@/utils'
import { login } from '@/api'

export default {
  name: 'HomeView',

  data () {
    return {
      loading: false
    }
  },
  created () {
    const code = getQueryParam('code')
    debugger
    if (code) { // 拿到code先判断使用过没有   授权才有code
      this.handleCode(code)
    } else { // 未授权前
      const id = getQueryParam('id')
      if (id) { // 从discord过来
        if (localStorage.getItem('token')) {
          const userinfo = JSON.parse(localStorage.getItem('userinfo'))
          if (id === userinfo.userId) {
            return this.$router.push('/pay')
          } else {
            setTimeout(this.handleAuth, 500)
          }
        } else {
          setTimeout(this.handleAuth, 500)
        }
      }
      // 不从discord过来 正常登陆
    }
  },
  methods: {
    handleCode (code) { // 授权完回调操作
      let codearr = JSON.parse(localStorage.getItem('logincode'))
      if (!codearr) codearr = []
      if (codearr.includes(code)) {
        return
      } else { // 没用过存一下
        codearr.push(code)
        if (codearr.length > 5) {
          codearr.shift()
        }

        this.handleLogin(code)
      }
      localStorage.setItem('logincode', JSON.stringify(codearr))
      // this.handleLogin(code)
    },
    handleAuth () {
      if (this.loading) return
      const prdid = '1197732968895107212'
      const testid = '1189110622495973396'
      const id = process.env.VUE_APP_ENV === 'production' ? prdid : testid
      const url = `https://discord.com/oauth2/authorize?client_id=${id}&response_type=code&redirect_uri=${encodeURIComponent(process.env.VUE_APP_DiSCORD_CALLBACK)}&scope=identify+email+connections`
      console.log(process.env.VUE_APP_DiSCORD_CALLBACK)
      window.location.href = url

      // console.log('auth')
    },
    handleLogin (code) {
      this.loading = true
      login({
        code
      }).then(res => {
        console.log(res)
        localStorage.setItem('userinfo', JSON.stringify(res))
        localStorage.setItem('token', res.token)
        const path = localStorage.getItem('403from')
        if (path) {
          localStorage.removeItem('403from')
          this.$router.push({
            name: path
          })
        } else {
          this.$router.push('/history')
        }
        // this.$router.push('/pay')
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .login-wrap{
    background-image: url("@/assets/login-bg.jpg");
    background-position: center;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    .auth-wrap{
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      //justify-content: center;
      flex-direction: column;
      padding-top: 43px;
      width: 660px;
      height: 406px;
      background: #313338;
      border-radius: 6px 6px 6px 6px;
      .title{

      }
      .p1{
        margin-top: 45px;
        margin-bottom: 11px;
        text-align: center;
        height: 45px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 32px;
        color: #FFFFFF;
        line-height: 38px;
        font-style: normal;
        text-transform: none;
      }
      .p2{
        width: 360px;
        height: 34px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: rgba(255,255,255,0.85);
        line-height: 14px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        margin-left: 20px;
      }
      .auth-btn{
        background: #9BD322;
        &:hover{
          background-color: #AAE823;
        }
        width: 400px;
        height: 43px;
        line-height: 43px;

        border-radius: 4px 4px 4px 4px;
        margin-top: 60px;
        display: flex;
        align-content: center;
        justify-content: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #333333;
        text-align: center;
        font-style: normal;
        text-transform: none;
        cursor: pointer;
      }
    }
  }

</style>
