import { message } from 'ant-design-vue'
import axios from 'axios'
import router from '@/router'

const OK = 2000
const errorCodeArr = ['403', '404', '500']

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // withCredentials: true,
  timeout: 80000
  // timeout: 2 * 60 * 1000
})

service.interceptors.request.use(

  config => {
    config.headers['access-token'] = localStorage.getItem('token')
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  async res => {
    if (res.data.code === 20000) {
      return res.data.data
    } else {
      message.warning(res.data.message)
      if (res.data.code === 40099) {
        localStorage.removeItem('token')
        if (router.currentRoute.name !== 'login') {
          localStorage.setItem('403from', router.currentRoute.name)
        }
        router.push('/login')
      }
      return Promise.reject(res.data.message)
    }
  },
  async error => {
    console.log(error, 11111111);
    message.warning('network error')
    return Promise.reject(error)
  }
)

export default service
