import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login.vue'
import Pay from '../views/Pay.vue'
import Payres from '../views/Payres.vue'
import Showcase from '../views/Showcase.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/pay'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/pay',
    name: 'pay',
    component: Pay
  },
  {
    path: '/payres',
    name: 'payres',
    component: Payres
  },
  {
    path: '/history',
    name: 'Showcase',
    component: Showcase
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
