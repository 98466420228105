<template>
  <div class="res-wrapper">
    <div class="logo">
      <img src="../assets/animix.png" alt="" >
    </div>
    <div class="gif" v-if="type===0">
      <img src="../assets/1.gif">
      <div>Pending</div>
    </div>
    <div v-else class="content-wrap">
      <img src="../assets/resimg.png" alt="">
      <div class="des1">{{type===1?'Subscribed':'Payment Failed'}}</div>
      <div class="des2" v-if="type===1">
        <span>Expires</span>
        <span >{{ exptime }}</span>
      </div>
      <div class="des3">
        {{type===1?'Your payment has been processed. Visit our community and have fun!':'Unfortunately, your payment was not successfully processed. Please try agian later or contact our support team.'}}
      </div>
      <div class="btn-wrap">
        <div class="btn1" @click="$router.push('/pay')">Back</div>
        <div class="btn2" @click="handleContact">{{type===1?'Visit Community ':'Contact Us'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { confirmOrder, getuserinfo, query } from '@/api'
import moment from 'moment'
import { getQueryParam } from '@/utils'
export default {
  name: 'payres',

  components: {
  },
  data () {
    return {
      type: 0, // 0 等待 1支付成功 2支付失败
      orderId: this.$route.query.orderId || '',
      exptime: ''
    }
  },
  created () {
    // if (this.orderId) {
    //   setTimeout(() => {
    //     this.queryid(this.orderId)
    //   }, 3000)
    // }
    const paypalid = getQueryParam('paymentId')
    const payerid = getQueryParam('PayerID')
    if (payerid && payerid) { // 从paypal回调回来
      this.handlePaypalCb(paypalid, payerid)
    } else {
      this.$router.push('/pay')
    }
  },
  methods: {
    handlePaypalCb (paypalid, payerid) {
      const orderid = localStorage.getItem('paypalorderid')
      const payid = localStorage.getItem('paypalpayid')
      if (orderid) {
        console.log('成功拿到缓存对orderid,并销毁', orderid)
        console.log('成功拿到缓存对payid,并销毁', payid)
        localStorage.removeItem('paypalorderid')
        localStorage.removeItem('paypalpayid')
        confirmOrder({ // 付款
          payId: payid,
          extraData: JSON.stringify({ paypalid, payerid })
        }).then(() => {
          setTimeout(() => {
            this.queryid(orderid)
          }, 3000)
        }).catch(e => {
          this.type = 2
        })
      }
    },
    handleContact () {
      if (this.type === 1) {
        window.open('https://discord.gg/yG67m4aq3m', '_blank')
      } else {
        location.href = 'mailto:animix_support@cue.group'
      }
    },
    async queryid (id) {
      try {
        const res = await query(id)()
        if (res.logisticsStatusCode >= 402) {
          this.type = 1
          const userinfo = localStorage.getItem('userinfo')
          if (userinfo) {
            this.userinfo = JSON.parse(userinfo)
            getuserinfo(this.userinfo.userId)().then(res => {
              this.exptime = moment(res.subscribeExpire).format('MMMM Do, YYYY HH:mm')
            })
          }
        } else {
          this.type = 2
        }
      } catch (e) {
        this.type = 2
      }
    }
  }
}
</script>
<style lang="less" scoped>
.res-wrapper{
  position: relative;
  background-color: #0B0C0F;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  .logo{
    position: absolute;
    left: 24px;
    top: 24px;
    img{
      width: 100%;
    }
  }
  .gif{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 28px;
    text-align: center;
  }
  .content-wrap{
    width: 400px;
    height: 388px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: rgba(255,255,255,0.85);
    .des1{
      margin-top: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 24px;
      color: #FFFFFF;
    }
    .des2{
      display: flex;
      align-content: center;
      justify-content: space-between;
      margin-top: 18px;

    }
    .des3{
      margin-top: 24px;
      text-align: left;
    }
    .btn-wrap{
      display: flex;
      margin-top: 24px;
      .btn1{
        cursor: pointer;
        width: 190px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #9BD322;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #9BD322;
        background-color: #0B0C0F;
        &:hover{
          background-color:rgba(155,211,34,0.2);
        }
      }
      .btn2{
          background: #9BD322;
          &:hover{
            background-color: #AAE823;
          }
          cursor: pointer;
          margin-left: 24px;
          width: 190px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #0B0C0F;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #0B0C0F;
      }
    }
  }
}

</style>
