<template>
  <div class="pay-wrap">
    <div class="left">
      <div class="top">
        <img src="../assets/animix.png" alt="" class="logo">
        <button class="btn1" @click="$router.push('/history')" style="cursor: pointer">History</button>
        <button class="btn1 active" @click="$router.push('/pay')" style="cursor: pointer;margin-top: 8px">Subscribe</button>
      </div>
      <div class="bottom">
        <div class="menu">
          <div class="menu-item" @click="handlejump" style="cursor: pointer"><img style="margin-right: 8px" src="../assets/menulogo1.png" alt="">Visit Community</div>
          <a class="menu-item" href="mailto:animix_support@cue.group"><img style="margin-right: 8px" src="../assets/menulogo2.png" alt="" >Contact Us</a>
          <div class="menu-item" style="opacity: 0.8;"><img style="margin-right: 8px" src="../assets/menulogo3.png" alt="">{{ userinfo.userName }}</div>
          <div class="menu-item logout" @click="handleLogout"><img style="margin-right: 8px" src="../assets/menulogo4.png" alt="" >Sign Out</div>
        </div>

      </div>

    </div>
    <div class="right">
      <div class="title">Choose Your Plan</div>
      <div class="scroll-wrap">
        <div class="plan-wrap">
          <div class="plan plan1">
            <div class="plan-des1">Free</div>
            <div class="plan-des2">
              <div class="price">$0<span class="mon"> / mon</span></div>
            </div>
            <div class="plan-btn" v-if="!level">Current Plan</div>
            <div class="check-list" :class="{lv:level===0}">
              <div class="check-item"><img src="../assets/check-circle.png" alt="" style="margin-right: 4px">10 free generations</div>
              <div class="check-item"><img src="../assets/check-circle.png" alt="" style="margin-right: 4px">1 digital avatar</div>
              <div class="check-item"><img src="../assets/check-circle.png" alt="" style="margin-right: 4px">1 free avatar modification</div>
            </div>
          </div>
          <div class="plan plan2">
            <div class="plan-des1">Premium</div>
            <div class="plan-des2">
              <div class="price">${{price || '4.99'}}<span class="mon"> / mon</span></div>
            </div>
            <div class="plan-btn" @click="handlePay"><img style="width: 20px;height: 20px;margin-right: 6px;transform: translateY(-2px)" src="../assets/paypal-icon.png" alt="">{{level?'Renew':'Subscribe'}}</div>
            <div class="time" v-if="level">Your plan expires: {{ moment(exptime).format('MMMM Do, YYYY HH:mm') }}</div>
            <div v-else style="height: 18px;background-color: transparent;width: 200px"></div>

            <div class="check-list">
              <div class="check-item"><img src="../assets/check-circle.png" alt="" style="margin-right: 4px">Unlimited generations</div>
              <div class="check-item"><img src="../assets/check-circle.png" alt="" style="margin-right: 4px">1 digital avatar</div>
              <div class="check-item"><img src="../assets/check-circle.png" alt="" style="margin-right: 4px">3 avatar modifications / month</div>
            </div>
          </div>
        </div>
        <div class="tip tip1"><span style="color: #C94F35">*</span> Premium membership is valid for 720 hours, equivalent to 24 hours * 30 days.</div>
        <div class="tip tip2"><span style="color: #C94F35">*</span> You can repurchase at any time to extend your plan.</div>
      </div>

    </div>
  </div>
</template>

<script>
import { createOrder, confirmOrder, getuserinfo, logout, getprice } from '@/api'
import { getQueryParam } from '@/utils'
import moment from 'moment'
export default {
  name: 'pay',
  created () {
    const userinfo = localStorage.getItem('userinfo')
    if (userinfo) {
      getprice().then(res => {
        this.price = res[1].price
      })
      this.userinfo = JSON.parse(userinfo)
      getuserinfo(this.userinfo.userId)().then(res => {
        this.exptime = res.subscribeExpire
        this.level = res.level
      })
    } else {
      this.$router.push('/login')
    }
    // const paypalid = getQueryParam('paymentId')
    // const payerid = getQueryParam('PayerID')
    // if (payerid && payerid) { // 从paypal回调回来
    //   this.handlePaypalCb(paypalid, payerid)
    // }
  },
  data () {
    return {
      price: '',
      userinfo: '',
      exptime: '',
      level: 0,
      isPaying: false,
      orderId: this.$route.query.orderId || ''
    }
  },
  methods: {
    moment,
    async handleLogout () {
      await logout()
      localStorage.removeItem('userinfo')
      localStorage.removeItem('token')
      this.$router.push('/login')
    },
    async handlePay () {
      if (this.isPaying) return
      this.isPaying = true
      try {
        const res = await createOrder({

          productType: 'MONTHLY',
          pay: {
            payType: 'PAYPAL',
            // returnUrl: 'https://saas.test.shuchuandata.com/k8s/panda-test/mini-anymix/landingPage',
            returnUrl: process.env.VUE_APP_PAY_URL1,
            extraData: "{\"quantity\": 1}"
          }
        })
        const orderId = res.orderId
        this.orderId = orderId
        localStorage.setItem('paypalorderid', orderId)
        const data = res.payment.data

        const data1 = JSON.parse(data)
        // const paypalid = data1.paypalid
        localStorage.setItem('paypalpayid', data1.payid)
        window.location.href = data1.approvalUrl
      } catch (e) {
        this.isPaying = false
      }
    },
    handleClick () {
      console.log(111)
    },
    handlejump () {
      // location.href = 'https://discord.gg/x5JuResF'
      window.open('https://discord.gg/yG67m4aq3m', '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.pay-wrap{
  display: flex;
  background-color: #0B0C0F;
  width: 100vw;
  height: 100vh;
  //min-height: 720px;
  min-width: 920px;
  .left{
    width: 210px;
    padding-top: 24px;
    padding-left: 24px;
    padding-bottom: 24px;
    text-align: left;
    display: flex;
    flex-direction: column;
    //align-content: center;
    justify-content: space-between;
    .top{
      .logo{
        width: 120px;
        height: 51px;
      }
      .btn1{
        &.active{
          background-color: #AAE823;
          color: #333333;
        }
        &:hover{
          background-color: #AAE823;
          color: #333333;
        }
        margin-top: 24px;
        width: 159px;
        height: 40px;
        line-height: 40px;
        color: #FFFFFF;
        background: transparent;
        border-radius: 78px 78px 78px 78px;
        border: none;
      }
    }
    .bottom{
      .menu{
        .menu-item{
          //display: flex;
          //align-content: center;
          //justify-content: center;
          font-size: 14px;
          color: rgba(255,255,255,0.65);
          text-align: left;
          height: 30px;
          line-height: 30px;
          color: white;
          opacity: 0.8;
          &:hover{
            opacity: 1;
          }
        }
        .logout{
          cursor: pointer;
          &:hover{
            color: white;
          }
          padding-left: 8px;
          transform: translateX(-8px);
          width: 117px;
          height: 32px;
          background: rgba(255,255,255,0.15);
          border-radius: 73px 73px 73px 73px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: rgba(255,255,255,0.65);
        }
      }

    }
    }
  .right{
    display: flex;
    flex-direction: column;
    align-content: center;
    flex: 1;
    flex-wrap: nowrap;
    //overflow-y: scroll;
    //min-height: 700px;
    //height: 100vh;
    .title{
      margin-top: 48px;
      margin-bottom: 48px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 36px;
      color: #FFFFFF;
      line-height: 42px;
      text-align: left;
      font-style: normal;
      text-align: center;
    }
    .scroll-wrap{
      height: 450px;
      overflow-y: scroll;
      &::-webkit-scrollbar, /deep/ ::-webkit-scrollbar {
        width: 0;
        height: 0;
        display: none;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
      }
      margin-bottom: 50px;
      padding-left: calc(50% - 350px);
      .plan-wrap{
        display: flex;
        text-align: left;
        .plan{
          width: 323px;
          height: 371px;
          background: rgba(255,255,255,0.05);
          border-radius: 16px 16px 16px 16px;
          border: 1px solid rgba(255,255,255,0.1);
          padding: 24px;
        }
        .plan1{
          margin-right: 48px;
          .plan-btn{
            color: #FFFFFF;
            background: rgba(255,255,255,.3);
            margin-top: 10px;
            margin-bottom: 8px;
            width: 275px;
            height: 40px;
            border-radius: 4px 4px 4px 4px;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            line-height: 40px;

          }
          .plan-des1{
            font-weight: 500;
            font-size: 18px;
            color: #FFFFFF;
          }
          .plan-des2{
            margin-top: 12px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 32px;
            color: #FFFFFF;
            .mon{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: rgba(255,255,255,0.65);
            }
          }
          .check-list{
            margin-top: 105px;
            &.lv{
              margin-top: 52px;
            }
            .check-item{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              line-height: 22px;
              height: 22px;
              font-size: 14px;
              color: rgba(255,255,255,0.85);
            }
          }
        }
        .plan2{
          margin-right: 48px;
          .plan-des1{
            font-weight: 500;
            font-size: 18px;
            color: #FFFFFF;
          }
          .plan-des2{
            margin-top: 12px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 32px;
            color: #FFFFFF;
            .mon{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: rgba(255,255,255,0.65);
            }
          }
          .plan-btn{
            background: #9BD322;
            &:hover{
              background-color: #AAE823;
            }
            cursor: pointer;
            margin-top: 10px;
            margin-bottom: 8px;
            width: 275px;
            height: 40px;
            border-radius: 4px 4px 4px 4px;
            text-align: center;
            color: #333;
            font-size: 14px;
            font-weight: 600;
            line-height: 40px;

          }
          .time{
            text-align: center;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: rgba(255,255,255,0.85);
          }
          .check-list{
            margin-top: 28px;
            .check-item{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              line-height: 22px;
              height: 22px;
              font-size: 12=4px;
              color: rgba(255,255,255,0.85);
            }
          }
        }
      }

      .tip{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        text-align: left;
        font-style: normal;
        color: rgba(255,255,255,.85);
      }
      .tip1{
        width: 690px;
        //height: 34px;
        margin-top: 32px;
        margin-bottom: 16px;
      }
    }
  }

}

</style>
