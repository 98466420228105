<template>
  <div class="pay-wrap">
    <div class="left">
      <div class="top">
        <img src="../assets/animix.png" alt="" class="logo">
        <button class="btn1 active" @click="$router.push('/history')" style="cursor: pointer">History</button>
        <button class="btn1"  @click="$router.push('/pay')" style="cursor: pointer;margin-top: 8px">Subscribe</button>
      </div>
      <div class="bottom">
        <div class="menu">
          <div class="menu-item" @click="handlejump" style="cursor: pointer"><img style="margin-right: 8px" src="../assets/menulogo1.png" alt="">Visit Community</div>
          <a class="menu-item" href="mailto:animix_support@cue.group"><img style="margin-right: 8px" src="../assets/menulogo2.png" alt="" >Contact Us</a>
          <div class="menu-item" style="opacity: 0.8;"><img style="margin-right: 8px" src="../assets/menulogo3.png" alt="">{{ userinfo.userName }}</div>
          <div class="menu-item logout" @click="handleLogout"><img style="margin-right: 8px" src="../assets/menulogo4.png" alt="" >Sign Out</div>
        </div>

      </div>

    </div>
    <div class="right">
      <div id="gallery" class="gallery">
        <div>
          <template v-for="({url, cosKey, dar}, index) in imgarr">
            <div class="item" :key="cosKey"  v-if="shouldload(index)">
              <img :src='removeDomain(url)'  alt="" class="img" @load="handleonload">
              <div class="hoverdiv" ></div>
              <img  src="../assets/loadimg.png" alt="" class="loadimgicon" @click="()=>handledown(cosKey)" >
            </div>
            <div class="loading item" v-else :key="cosKey" :data-dar="dar"></div>
          </template>
        </div>
      </div>
      <template v-if="!imgarr.length && hasgetList">
        <div class="empty-wrap">
          <div class="des1">No images yet</div>
          <div class="des2">Visit our Discord community and create your stunning avatars!</div>
          <div class="btn-wrap">
            <div class="btn2" @click="handleContact">Visit Community</div>
          </div>

        </div>
      </template>

    </div>
  </div>
</template>

<script>
import { createOrder, confirmOrder, getuserinfo, logout, getprice, getimage, getdownloadurl } from '@/api'
import { getQueryParam, removeDomain } from '@/utils'
import img1 from '../assets/img1.png'
import img2 from '../assets/img2.png'
import img3 from '../assets/img3.png'
import moment from 'moment'
export default {
  name: 'Showcase',
  created () {
    console.log(this.$router)
    const userinfo = localStorage.getItem('userinfo')
    if (userinfo) {
      getprice().then(res => {
        this.price = res[1].price
      })
      this.userinfo = JSON.parse(userinfo)
      getuserinfo(this.userinfo.userId)().then(res => {
        this.exptime = res.subscribeExpire
        this.level = res.level
      })

      getimage().then(res => {
        // if (res) {
        this.imgarr = res || []
        this.hasgetList = true
        // setTimeout(() => {
        this.adjustGalleryColumns()
        // }, 3000)
        // }
      })
    } else {
      this.$router.push('/login')
    }
  },
  mounted () {
    window.addEventListener('resize', this.adjustGalleryColumns);
    document.getElementById('gallery').addEventListener('scroll', (e) => {
      if (this.scrollTopAlready < e.target.scrollTop) {
        this.scrollTopAlready = e.target.scrollTop
      }
    })
  },
  data () {
    return {
      img1,
      imgarr: [],
      price: '',
      userinfo: '',
      exptime: '',
      level: 0,
      isPaying: false,
      orderId: this.$route.query.orderId || '',
      column: 5,
      scrollTopAlready: 0,
      hasgetList: false
    }
  },
  computed: {

  },
  methods: {
    moment,
    removeDomain,
    handleContact () {
      window.open('https://discord.gg/yG67m4aq3m', '_blank')
    },
    handleonload () {
      this.adjustGalleryColumns()
    },
    shouldload (index) {
      if (index < 20) return true // 默认加载20张图
      // if (index === 2) debugger
      if (Math.ceil((index + 1) / this.columns) * 260 < (this.scrollTopAlready * 1.3 + window.innerHeight)) {
        return true
      }
      return false
    },
    handledown (imageUrl) {
      getdownloadurl(imageUrl)().then(res => {
        if (res) {
          window.location.href = `https:${res}`
        }
      })
    },
    adjustGalleryColumns () {
      console.log('adjustGalleryColumns')
      // debugger
      console.log('xxx')
      // debugger
      const gallery = document.getElementById('gallery');
      const items = document.querySelectorAll('.item');
      if (items.length < this.imgarr?.length) {
        return setTimeout(() => {
          this.adjustGalleryColumns()
        }, 100)
      }
      this.columns = 5; // 默认列数
      // 小于1000 移动端布局
      if (window.innerWidth < 1100) {
        this.columns = 2;
      } else if (window.innerWidth < 1440) {
        this.columns = 3;
      } else if (window.innerWidth < 1770) {
        this.columns = 4;
      }
      const padding = 6
      const columnWidths = gallery.offsetWidth / this.columns - (this.columns - 1) * padding;
      // const columnWidths = 280;

      const columnHeights = Array(this.columns).fill(0);
      const x = (window.innerWidth - 210 - this.columns * columnWidths) / 2
      gallery.style.marginLeft = `${x}px`

      items.forEach((item, i) => {
        // debugger
        // item.style.width = columnWidth;

        const columnIndex = i % this.columns;
        item.style.top = `${columnHeights[columnIndex]}px`;
        item.style.left = `${columnIndex * (columnWidths + padding / 2)}px`;

        item.style.width = `${columnWidths - padding}px`; // 减去padding的宽度
        if (item.dataset.dar) {
          const dardata = item.dataset.dar.split(':')
          const dar = dardata[1] / dardata[0]
          item.style.height = `${(columnWidths - padding) * dar}px`; // 默认1:1
        }

        // item.style.width = `280px`; // 减去padding的宽度
        // item.style.width = columnWidths; // 减去padding的宽度
        // if (item.className.includes('loading')) {
        //   item.style.height = item.style.width
        // }
        columnHeights[columnIndex] += item.offsetHeight + padding * 2; // 更新列的高度
      });
      // 更新gallery的高度
      const maxHeight = Math.max(...columnHeights);
      gallery.style.height = `${maxHeight}px`;
    },
    async handleLogout () {
      await logout()
      localStorage.removeItem('userinfo')
      localStorage.removeItem('token')
      this.$router.push('/login')
    },
    async handlePay () {
      if (this.isPaying) return
      this.isPaying = true
      try {
        const res = await createOrder({

          productType: 'MONTHLY',
          pay: {
            payType: 'PAYPAL',
            // returnUrl: 'https://saas.test.shuchuandata.com/k8s/panda-test/mini-anymix/landingPage',
            returnUrl: process.env.VUE_APP_PAY_URL1,
            extraData: "{\"quantity\": 1}"
          }
        })
        const orderId = res.orderId
        this.orderId = orderId
        localStorage.setItem('paypalorderid', orderId)
        const data = res.payment.data

        const data1 = JSON.parse(data)
        // const paypalid = data1.paypalid
        localStorage.setItem('paypalpayid', data1.payid)
        window.location.href = data1.approvalUrl
      } catch (e) {
        this.isPaying = false
      }
    },
    handlejump () {
      // location.href = 'https://discord.gg/x5JuResF'
      window.open('https://discord.gg/yG67m4aq3m', '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.pay-wrap{
  min-width: 710px;
  display: flex;
  background-color: #0B0C0F;
  width: 100vw;
  height: 100vh;
  //min-height: 720px;
  //min-width: 920px;
  .left{
    width: 210px;
    padding-top: 24px;
    padding-left: 24px;
    padding-bottom: 24px;
    text-align: left;
    display: flex;
    flex-direction: column;
    //align-content: center;
    justify-content: space-between;
    .top{
      .logo{
        width: 120px;
        height: 51px;
      }
      .btn1{
        &.active{
          background-color: #9bd322;
          color: #333333;
        }
        &:hover{
          background-color: #AAE823;
          color: #333333;
        }
        margin-top: 24px;
        width: 159px;
        height: 40px;
        line-height: 40px;
        color: #FFFFFF;
        background: transparent;
        border-radius: 78px 78px 78px 78px;
        border: none;
      }
    }
    .bottom{
      .menu{
        .menu-item{
          //display: flex;
          //align-content: center;
          //justify-content: center;
          font-size: 14px;
          color: rgba(255,255,255,0.65);
          text-align: left;
          height: 30px;
          line-height: 30px;
          color: white;
          opacity: 0.8;
          &:hover{
            opacity: 1;
          }
        }
        .logout{
          cursor: pointer;
          &:hover{
            color: white;
          }
          padding-left: 8px;
          transform: translateX(-8px);
          width: 117px;
          height: 32px;
          background: rgba(255,255,255,0.15);
          border-radius: 73px 73px 73px 73px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: rgba(255,255,255,0.65);
        }
      }

    }
  }
  .right{
    display: flex;
    flex-direction: column;
    align-content: center;
    flex: 1;
    flex-wrap: nowrap;
    padding: 26px;
    .gallery {
      position: relative;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: -5px;
      height: 90vh;
      overflow-y: scroll;
      &::-webkit-scrollbar, /deep/ ::-webkit-scrollbar {
        width: 0;
        height: 0;
        display: none;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
      }
    }
    .empty-wrap{
      margin-top: 200px;
      display: flex;
      flex-direction: column;
      align-content: center;
      //width: 400px;
      height: 388px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255,255,255,0.85);
      .des1{
        margin-top: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 24px;
        color: #FFFFFF;
      }
      .des2{
        width: 100%;
        text-align: center;
        margin-top: 8px;

      }
      .btn-wrap{
        margin-top: 8px;
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        .btn2{
          background: #9BD322;
          &:hover{
            background-color: #AAE823;
          }
          cursor: pointer;
          width: 190px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #0B0C0F;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #0B0C0F;
        }
      }

    }
    .item {
      position: absolute;
      flex: 0 0;
      //padding: 5px;
      .hoverdiv{
        z-index: 5;
        position: absolute;
        background-color: transparent;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
      }
      &:hover{
        .hoverdiv{
          background: linear-gradient( 180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
          opacity: 0.5;
        }

      }
      &.loading{
        background-color: #333333;
        //background-color: red;
      }
      //.loading{
      //  background-color: #333333;
      //  width: 260px;
      //  height: 260px;
      //  display: block;
      //}
      .img{
        width: 100%;
        height: auto;
        display: block;

      }
      .loadimgicon{
        cursor: pointer;
        z-index: 11;
        position: absolute;
        width: 8.5%;
        height: auto;
        right: 24px;
        bottom: 24px;
      }
    }
    //.item img {
    //  width: 280px;
    //  height: auto;
    //  display: block;
    //}
  }

}

</style>
