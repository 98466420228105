import service from './request'
const prefix = '/api'
const post = url => {
  return (data, config = { simplify: true }) => {
    return service.post(prefix + url, data, config)
  }
}
const get = url => {
  return (config = { simplify: true }) => {
    return service.get(prefix + url, { simplify: true, ...config })
  }
}
export const login = post('/login')
export const logout = post('/logOut')

export const getuserinfo = (id) => get(`/user/${id}`)

export const createOrder = post('/pay/order')

export const confirmOrder = post('/pay/confirm')
export const query = (id) => get(`/pay/order/brief/${id}`)

export const getprice = post(`/subscribe/levelList`)

export const getimage = get('/image/list')

export const getdownloadurl = cosKey => get(`/image/download/url?path=/${cosKey}`)
